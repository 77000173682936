import client from '../client';
import { exportFile } from '../requestDownloadFile';

export default {
  getFinanceSellerBillingsList: data => {
    return client.get('finance/seller/billings/list', {params:data}).then(res => res.data);
  },
  getFinanceSellerBillingsDetail: data => {
    return client.get('finance/seller/billings/detail', {params:data}).then(res => res.data);
  },
  financeSellerBillingsSubmit: data => {
    return client.post('finance/seller/billings/submit', data).then(res => res.data);
  },
  financeSellerBillingsDetailExport: data => {
    return exportFile('finance/seller/billings/detail/export', data.qs, data.fileName);
  },
  financeSellerBillingsDownloadInvoice: data => {
    return client.get('finance/seller/billings/downloadInvoice', {params:data}).then(res => res.data);
  },
  financeSellerBillingsInvoice: data => {
    return client.get('finance/seller/billings/invoice', {params:data}).then(res => res.data);
  },
  financeSellerPaymentPaypalCheckoutOrders: data => {
    return client.post('finance/seller/payment/paypal/checkout/orders', data).then(res => res.data);
  },
  financeSellerPaymentPaypalCapture: data => {
    return client.post('finance/seller/payment/paypal/orders/capture', data).then(res => res.data);
  },
  financeSellerPaymentPaypalOrdersCaptures: data => {
    return client.get(`finance/seller/payment/paypal/orders/captures/${data.paypalCaptureId}`, {}).then(res => res.data);
  },
}
