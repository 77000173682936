/*
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-12 16:14:18
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-13 10:38:32
 * @FilePath: /web_artemisads/src/utils/index.js
 * @Description: 工具函数
 */
import md5 from 'md5';
import axios from "axios";
import moment from 'moment';
import { message } from "ant-design-vue";

/**
 * @description: 复制到粘贴板
 * @param {*} text
 * @return {*} viod
 */
export function copyToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          message.success('Copied Successfully');
        })
        .catch((err) => {
          console.error('复制失败:', err);
        });
    } else {
      console.warn('Clipboard API 不支持，使用 fallback 方法');
      fallbackCopyToClipboard(text);
    }
  }
  
  // Fallback 方法（使用 execCommand）
  function fallbackCopyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      message.success('Copied Successfully');
    } catch (err) {
      console.error('复制失败:', err);
    }
    document.body.removeChild(textarea);
  }

/**
 * @description: 格式化传入文本为富文本样式
 * @param {*} body
 * @return {*} 富文本
 */
export function formattedText (body) {
    // 将换行符转换为 <br>，链接转换为超链接
    const open = '<p style="font-family: Sora; font-weight: 400; font-size: 14px; color: rgba(15, 6, 41, .9); letter-spacing: 0.4px; margin-top: 8px; line-height: 22px; min-height: 22px;">';
    const close = '</p>';
    return open
    + body.trim().replace(/(https?\:\/\/[^\s\r\n\b]+)/g, '<a href="$1" target="_blank">$1</a>').replace(/\r\n/g, '\n').replace(/\n+/g, close + '\n' + open)
    + close
}

/**
 * @file: blob文件
 * @url
 * @return res
 */

const imageRequest = axios.create({
  baseURL: 'api/',
  timeout: 20000,
  headers: {
    'Content-Type': 'image/jpeg',
    // 'Authorization': store.state.token
  }
});

async function hashSHA256(message) {
  // 将字符串转换为 Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // 调用 SubtleCrypto 的 digest 方法生成 SHA-256 哈希
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // 将 ArrayBuffer 转换为十六进制字符串
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export const uploadImg = async ({ file, url }) => {
  const ts = moment().format('x'), accessToken = '7uddl5',  biz = 'artemisads';
  const secret = await hashSHA256('madash' + ts);
  let res = {};
  if (file) {
    res = await imageRequest.put(`https://thumbimg.artemisads.com/api/storage/v2/file/a.jpg?biz=${biz}&accessToken=${accessToken}&secret=${secret}&ts=${ts}`, file);
  } else {
    res = await imageRequest.put(`https://thumbimg.artemisads.com/api/storage/v2/file/${url}?biz=${biz}&accessToken=${accessToken}&secret=${secret}&ts=${ts}`);
  }
  if (res.data?.success) {
    // let protocal = 'https://', cacheServer = `imgcache.dealmoon.com`;
    // let imageUrl = res.data.data.url;
    // imageUrl = imageUrl.replace(protocal, '');
    // imageUrl = `${imageUrl}_0_0_1`;
    // // keep secret!
    // let key = md5(`&6#dealmoon.magic.GG${imageUrl}`).substr(0, 4);
    // imageUrl = `${protocal}${cacheServer}/${imageUrl}_${key}.jpg`;
    // res.data.data.url = imageUrl;
    return res.data;
  }
  return res;
}

export const imgCache = (url = '', size = '0_0', type = '1') => {
  if (!url) return '';
  let protocal = 'https:';
  let orginUrl = '';
  // 如果图片是//开头，加上protocal，防止生成连接后出错
  if (/^\/\//.test(url)) {
    url = `${protocal}//${url}`;
  }
  // 如果判断图片已经做了转换，提取出原图再转换
  let regexp = /^((https?\:)\/\/\w+cache\.dealmoon.com\/)(.+)(_\d+_\d+_\d+_[0-9a-z]{4}\.[\w]{2,4})$/;
  let matches = url.match(regexp);
  if (matches === null) {
    orginUrl = url.replace(/^https?:\/\//, '');
  } else {
    [, , protocal, orginUrl] = matches;
  }
  let commboUrl = `${orginUrl}_${size}_${type}`;
  // keep secret!
  let key = md5(`&6#dealmoon.magic.GG${commboUrl}`).substr(0, 4);
  let suffix = url.split('.').pop();
  // 获取图片服务器
  let cacheServer = `imgcache.dealmoon.com`;
  return `${protocal}//${cacheServer}/${commboUrl}_${key}.${suffix || 'jpg'}`;
};

export function getTimeZoneStr(countryCode = 'US', isShort = true) {
  if (countryCode == 'US' || countryCode == 'CA') {
    return isShort ? 'PT' : 'Paciﬁc Time Zone (PT)'
  } else if (countryCode == 'FR' || countryCode == 'DE') {
    return isShort ? 'CET' : 'Central European Time Zone (CET)'
  } else if (countryCode == 'UK') {
    return isShort ? 'GMT' : 'Greenwich Mean Time Zone (GMT)'
  }
}

export function buildQuery(data) {
  return Object.keys(data).map(key => {
    return `${key}=${data[key]}`
  }).join('&')
}

export function getQuery(name, u = location.href) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
    r = u.substr(u.indexOf("?") + 1).match(reg)

  return r != null ? r[2] : "";
}