/*
 * @Author: Gang Jiang 
 * @Date: 2025-02-10 11:30:33 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-02-14 10:51:30
 */
import client from './client';
import { buildQuery } from '~/utils'

export default {
  getProductDetail: (id, data) => {
    return client.get(`cooperate/product/detail/${id}?${buildQuery(data)}`).then(res => res.data);
  },
  getBrandsList: data => {
    return client.get('cooperate/brand/list?' + buildQuery(data)).then(res => res.data);
  },
  getProductList: data => {
    return client.get('cooperate/product/find/list?' + buildQuery(data)).then(res => res.data);
  },
  getCategoryList: data => {
    return client.get('cooperate/category/aggregation/list?' + buildQuery(data)).then(res => res.data);
  },
  getTrackLink: data => {
    return client.get(`cooperate/product/${data.id}/tracking/link/init?${buildQuery(data)}`).then(res => res.data);
  },
  saveTrackLink: (productId, countryCode, data) => {
    return client.post(`cooperate/product/${productId}/tracking/link/save?countryCode=${countryCode}`, data).then(res => res.data);
  }
}