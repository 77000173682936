import client from '../client';

export default {
  getSellerDashboardDataStatistics: data => {
    return client.get(`seller/dashboard/data/statistics`, { params: data }).then(res => res.data);
  },
  getSellerDashboardNextSteps: data => {
    return client.get(`seller/dashboard/next/steps`, { params: data }).then(res => res.data);
  },
  sellerOnboardingCompleted: data => {
    return client.post(`seller/onboardingCompleted`, data).then(res => res.data);
  },
}
