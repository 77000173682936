import client from '../client';

export default {
  getPublisherDashboardDataStatistics: data => {
    return client.get(`publisher/dashboard/data/statistics`, { params: data }).then(res => res.data);
  },
  getPublisherDashboardNextSteps: data => {
    return client.get(`publisher/dashboard/next/steps`, { params: data }).then(res => res.data);
  },
  publisherOnboardingCompleted: data => {
    return client.post(`publisher/onboardingCompleted`, data).then(res => res.data);
  },
}
