import client from '../client';
import { exportFile } from '../requestDownloadFile';

export default {
  exportCommissionBrandReports: data => {
    return exportFile('publisher/commission/reports/brand/export', data.qs, data.fileName);
  },
  exportCommissionPerformanceReports: data => {
    return exportFile('publisher/commission/reports/performance/export', data.qs, data.fileName);
  },
  exportCommissionProductReports: data => {
    return exportFile('publisher/commission/reports/product/export', data.qs, data.fileName);
  },


  getPublisherCommissionReportsAgg: data => {
    return client.get(`publisher/commission/reports/agg`, { params: data }).then(res => res.data);
  },


  getPublisherCommissionReportsPerformance: data => {
    return client.get(`publisher/commission/reports/performance`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsPerformanceDailyMetric: data => {
    return client.get(`publisher/commission/reports/performance/daily-metric`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsBrand: data => {
    return client.get(`publisher/commission/reports/brand`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsBrandDailyMetric: data => {
    return client.get(`publisher/commission/reports/brand/daily-metric`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsBrandMetric: data => {
    return client.get(`publisher/commission/reports/brand/metric`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsProduct: data => {
    return client.get(`publisher/commission/reports/product`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsProductDailyMetric: data => {
    return client.get(`publisher/commission/reports/product/daily-metric`, { params: data }).then(res => res.data);
  },
  getPublisherCommissionReportsProductMetric: data => {
    return client.get(`publisher/commission/reports/product/metric`, { params: data }).then(res => res.data);
  },
  getPublisherBrandSearch: data => {
    return client.get(`publisher/brands/search`, { params: data }).then(res => res.data);
  },
  getPublisherProductSearch: data => {
    return client.get(`publisher/product/searchAsin`, { params: data }).then(res => res.data);
  },
  getPublisherBrandSearch: data => {
    return client.get(`publisher/brand/find/list`, { params: data }).then(res => res.data);
  },
  getPublisherProductSearch: data => {
    return client.get(`publisher/product/searchAsin`, { params: data }).then(res => res.data);
  },
}
